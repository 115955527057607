<script>
import appConfig from "../../../app.config";
import NavMenu from "../landing/inc/nav-menu.vue";
import Footer from "../landing/inc/footer.vue";

export default {
    page: {
        title: "Starter",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Starter",
            items: [{
                text: "Pages",
                href: "/",
            },
            {
                text: "Starter",
                active: true,
            },
            ],
        };
    },
    components: {
        NavMenu,
        Footer
    }
};
</script>

<template>
    <div class="layout-wrapper">

        <nav-menu></nav-menu>

        <div class="bg-overlay bg-overlay-pattern"></div>

        <section class="section header">
            <div class="bg-overlay"></div>
        </section>

        <section class="section">
            <b-container>

                <b-row class="justify-content-center mt-5">
                    <b-col lg="5">
                        <div class="text-center mb-4 pb-2">
                        <h4 class="fw-semibold fs-22">
                            다온샵 솔류션 제작가격
                        </h4>
                        <p class="text-muted mb-4 fs-15">
                            인쇄기획사, 인쇄소, POD센터, 후가공업체, 판촉물업체 등
                        </p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="justify-content-center">
                    <b-col xl="12">
                        <b-row v-if="false">
                            <b-col lg="3">
                                <b-card no-body class="pricing-box ribbon-box right">
                                <b-card-body class="p-4 m-2">
                                    <div class="ribbon-two ribbon-two-primary">
                                        <span>Popular</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-1 fw-semibold">인쇄기획사 쇼핑몰</h5>
                                        <p class="text-muted mb-0">인쇄기획사 견적모듈</p>
                                    </div>
                                    </div>
                                    <div class="pt-4 text-center">
                                        
                                        <h2 class="text-danger">
                                            500 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(제작비)</span>
                                        </h2>
                                        <h4>
                                            10 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(월운영비)</span>
                                        </h4>
                                        <div class="fs-11 text-muted">( 품목 및 옵션에 따라 최종가 산정 )</div>
                                    </div>
                                    <hr class="my-4 text-muted" />
                                    <div class="mt-4">
                                        <router-link to="/pages/contactus" class="
                                            btn btn-info
                                            w-100
                                            waves-effect waves-light
                                        ">견적문의</router-link>
                                    </div>
                                </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col lg="3">
                                <b-card no-body class="pricing-box">
                                <b-card-body class="p-4 m-2">
                                    <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-1 fw-semibold">독판인쇄소 쇼핑몰</h5>
                                        <p class="text-muted mb-0">독판인쇄소 견적모듈</p>
                                    </div>
                                    </div>
                                    <div class="pt-4 text-center">
                                        <h2 class="text-danger">
                                            2,000 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(제작비)</span>
                                        </h2>
                                        <h4>
                                            15 <span class="fs-13">만원부터</span> <span class="fs-13 text-muted">(월운영비)</span>
                                        </h4>
                                        <div class="fs-11 text-muted">( 품목 및 옵션에 따라 최종가 산정 )</div>
                                    </div>
                                    <hr class="my-4 text-muted" />
                                    <div class="mt-4">
                                        <router-link to="/pages/contactus" class="
                                            btn btn-info
                                            w-100
                                            waves-effect waves-light
                                        ">견적문의</router-link>
                                    </div>
                                </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col lg="3">
                                <b-card no-body class="pricing-box">
                                <b-card-body class="p-4 m-2">
                                    <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-1 fw-semibold">디지털인쇄소 쇼핑몰</h5>
                                        <p class="text-muted mb-0">디지털인쇄소 견적모듈</p>
                                    </div>
                                    </div>
                                    <div class="pt-4 text-center">
                                        <h2 class="text-danger">
                                            350 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(제작비)</span>
                                        </h2>
                                        <h4>
                                            15 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(월운영비)</span>
                                        </h4>
                                        <div class="fs-11 text-muted">( 품목 및 옵션에 따라 최종가 산정 )</div>
                                    </div>
                                    <hr class="my-4 text-muted" />
                                    <div class="mt-4">
                                        <router-link to="/pages/contactus" class="
                                            btn btn-info
                                            w-100
                                            waves-effect waves-light
                                        ">견적문의</router-link>
                                    </div>
                                </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col lg="3">
                                <b-card no-body class="pricing-box">
                                <b-card-body class="p-4 m-2">
                                    <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-1 fw-semibold">실사출력 쇼핑몰</h5>
                                        <p class="text-muted mb-0">실사, 현수막, 배너 견적모듈</p>
                                    </div>
                                    </div>
                                    <div class="pt-4 text-center">
                                        <h2 class="text-danger">
                                        300 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(제작비)</span>
                                        </h2>
                                        <h4>
                                            20 <span class="fs-13">만원부터</span> <span class="fs-13 text-muted">(월운영비)</span>
                                        </h4>
                                        <div class="fs-11 text-muted">( 품목 및 옵션에 따라 최종가 산정 )</div>
                                    </div>
                                    <hr class="my-4 text-muted" />
                                    <div class="mt-4">
                                        <router-link to="/pages/contactus" class="
                                            btn btn-info
                                            w-100
                                            waves-effect waves-light
                                        ">견적문의</router-link>
                                    </div>
                                </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row class="justify-content-center" v-if="false">
                    <b-col xl="12">
                        <b-row>
                            <b-col lg="3">
                                <b-card no-body class="pricing-box">
                                <b-card-body class="p-4 m-2">
                                    <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-1 fw-semibold">판촉/부자재/일반쇼핑몰</h5>
                                        <p class="text-muted mb-0">판촉물 견적모듈</p>
                                    </div>
                                    </div>
                                    <div class="pt-4 text-center">
                                        <h2 class="text-danger">
                                            250 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(제작비)</span>
                                        </h2>
                                        <h4>
                                            10 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(월운영비)</span>
                                        </h4>
                                        <div class="fs-11 text-muted">( 품목 및 옵션에 따라 최종가 산정 )</div>
                                    </div>
                                    <hr class="my-4 text-muted" />
                                    <div class="mt-4">
                                        <router-link to="/pages/contactus" class="
                                            btn btn-info
                                            w-100
                                            waves-effect waves-light
                                        ">견적문의</router-link>
                                    </div>
                                </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col lg="3">
                                <b-card no-body class="pricing-box ribbon-box left">
                                <b-card-body class="p-4 m-2">
                                    <div class="ribbon-two ribbon-two-danger">
                                        <span>New</span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-1 fw-semibold">후가공업체 웹사이트</h5>
                                        <p class="text-muted mb-0">인쇄기획사 견적모듈</p>
                                    </div>
                                    </div>
                                    <div class="pt-4 text-center">
                                        <h2 class="text-danger">
                                            250 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(제작비)</span>
                                        </h2>
                                        <h4>
                                            8 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(월운영비)</span>
                                        </h4>
                                        <div class="fs-11 text-muted">( 품목 및 옵션에 따라 최종가 산정 )</div>
                                    </div>
                                    <hr class="my-4 text-muted" />
                                    <div class="mt-4">
                                        <router-link to="/pages/contactus" class="
                                            btn btn-info
                                            w-100
                                            waves-effect waves-light
                                        ">견적문의</router-link>
                                    </div>
                                </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col lg="3">
                                <b-card no-body class="pricing-box">
                                <b-card-body class="p-4 m-2">
                                    <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-1 fw-semibold">지업사(종이) 쇼핑몰</h5>
                                        <p class="text-muted mb-0">디지털인쇄소 견적모듈</p>
                                    </div>
                                    </div>
                                    <div class="pt-4 text-center">
                                        <h2 class="text-danger">
                                            500 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(제작비)</span>
                                        </h2>
                                        <h4>
                                            15 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(월운영비)</span>
                                        </h4>
                                        <div class="fs-11 text-muted">( 품목 및 옵션에 따라 최종가 산정 )</div>
                                    </div>
                                    <hr class="my-4 text-muted" />
                                    <div class="mt-4">
                                        <router-link to="/pages/contactus" class="
                                            btn btn-info
                                            w-100
                                            waves-effect waves-light
                                        ">견적문의</router-link>
                                    </div>
                                </b-card-body>
                                </b-card>
                            </b-col>
                            <b-col lg="3">
                                <b-card no-body class="pricing-box">
                                <b-card-body class="p-4 m-2">
                                    <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <h5 class="mb-1 fw-semibold">CTP출력실 웹사이트</h5>
                                        <p class="text-muted mb-0">실사, 현수막, 배너 견적모듈</p>
                                    </div>
                                    </div>
                                    <div class="pt-4 text-center">
                                        <h2 class="text-danger">
                                            300 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(제작비)</span>
                                        </h2>
                                        <h4>
                                            15 <span class="fs-13">만원 부터</span> <span class="fs-13 text-muted">(월운영비)</span>
                                        </h4>
                                        <div class="fs-11 text-muted">( 품목 및 옵션에 따라 최종가 산정 )</div>
                                    </div>
                                    <hr class="my-4 text-muted" />
                                    <div class="mt-4">
                                        <router-link to="/pages/contactus" class="
                                            btn btn-info
                                            w-100
                                            waves-effect waves-light
                                        ">견적문의</router-link>
                                    </div>
                                </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row>
                <b-col lg="6">
                    <b-card no-body class="pricing-box text-center">
                    <b-row class="g-0">
                        <b-col lg="6">
                        <b-card-body class="h-100">
                            <div>
                                <h5 class="mb-1">종합/합판/대형인쇄소</h5>
                            </div>
                            <div class="py-4">
                                <h3>
                                        대형 인쇄쇼핑몰<br>맞춤개발
                                </h3>
                                <div class="fs-12 text-primary"> 협의 후 최종 개발가가 산정됩니다. </div>
                            </div>

                            <div class="text-center plan-btn mt-2">
                                <router-link to="/pages/contactus" class="btn btn-success w-sm waves-effect waves-light">개발문의</router-link>
                            </div>
                        </b-card-body>
                        </b-col>
                        <b-col lg="6">
                        <b-card-body class="border-start mt-4 mt-lg-0">
                            <b-card-header class="bg-light">
                            <h5 class="fs-15 mb-0">장점</h5>
                            </b-card-header>
                            <b-card-body class="pb-0">
                            <ul class="list-unstyled vstack gap-3 mb-0">
                                <li>
                                    <span class="text-success fw-semibold">-</span> 대형인쇄(합판) 쇼핑몰 구축경험 
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 대형인쇄(합판) ERP 구축경험
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 대형인쇄(합판) MES 구축경험
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 각종 인쇄비 견적모듈
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 각종 후가공 견적모듈
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 인쇄분야 맞춤 개발 가능
                                </li>
                            </ul>
                            </b-card-body>
                        </b-card-body>
                        </b-col>
                    </b-row>
                    </b-card>
                </b-col>

                <b-col lg="6">
                    <b-card no-body class="pricing-box ribbon-box ribbon-fill text-center">
                    <div class="ribbon ribbon-danger">Hot</div>
                    <b-row class="g-0">
                        <b-col lg="6">
                        <b-card-body class="h-100">
                            <div>
                            <h5 class="mb-1">운영/유지보수</h5>
                            </div>

                            <div class="py-4">
                                <h3>
                                    인쇄 쇼핑몰<br>운영 및 유지보수
                                </h3>
                                <div class="fs-12 text-primary">현재 운영하는 쇼핑몰을 운영해드립니다.</div>
                            </div>

                            <div class="text-center plan-btn mt-2">
                                <router-link to="/pages/contactus" class="btn btn-success w-sm waves-effect waves-light">운영문의</router-link>
                            </div>
                        </b-card-body>
                        </b-col>
                        <b-col lg="6">
                        <b-card-body class="border-start mt-4 mt-lg-0">
                            <b-card-header class="bg-light">
                            <h5 class="fs-15 mb-0">진행철차</h5>
                            </b-card-header>
                            <b-card-body class="pb-0">
                            <ul class="list-unstyled vstack gap-3 mb-0 text-left">
                                <li>
                                    <span class="text-success fw-semibold">1.</span> 개발환경분석
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">2.</span> 소스분석
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">3.</span> 운영협의
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">4.</span> 견적산출
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">5.</span> 월운영진행
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">7.</span> 기능개선
                                </li>
                            </ul>
                            </b-card-body>
                        </b-card-body>
                        </b-col>
                    </b-row>
                    </b-card>
                </b-col>

                </b-row>

                <b-row class="justify-content-center mt-5">
                    <b-col lg="4">
                        <div class="text-center mb-4 pb-2">
                        <h4 class="fw-semibold fs-22">다온 MES 개발단가 정책</h4>
                        <p class="text-muted mb-4 fs-15">
                            인쇄 생산공정관리시스테 맞춤형 개발단가 정책
                        </p>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                <b-col lg="6">
                    <b-card no-body class="pricing-box text-center">
                    <b-row class="g-0">
                        <b-col lg="6">
                        <b-card-body class="h-100">
                            <div>
                            <h5 class="mb-1">기본 모듈</h5>
                            </div>

                            <div class="py-4">
                            <h2 v-if="false">
                                700<span class="fs-13 text-muted">만원 부터</span>
                            </h2>
                            <h2>
                                별도 견적문의
                            </h2>
                            <h3 class="fs-13 text-primary"> 협의 후 최종 개발가가 산정됩니다. </h3>
                            </div>

                            <div class="text-center plan-btn mt-2">
                                <router-link to="/pages/contactus" class="btn btn-success w-sm waves-effect waves-light">개발문의</router-link>
                            </div>
                        </b-card-body>
                        </b-col>
                        <b-col lg="6">
                        <b-card-body class="border-start mt-4 mt-lg-0">
                            <b-card-header class="bg-light">
                            <h5 class="fs-15 mb-0">기본 기능:</h5>
                            </b-card-header>
                            <b-card-body class="pb-0">
                            <ul class="list-unstyled vstack gap-3 mb-0">
                                <li>
                                    <span class="text-success fw-semibold">-</span> 대쉬보드 
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 작업지시서 
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 생산관리
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 장비관리
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 외주관리
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 자재관리
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">-</span> 현황 및 통계
                                </li>
                            </ul>
                            </b-card-body>
                        </b-card-body>
                        </b-col>
                    </b-row>
                    </b-card>
                </b-col>

                <b-col lg="6">
                    <b-card no-body class="pricing-box ribbon-box ribbon-fill text-center">
                    <div class="ribbon ribbon-primary">New</div>
                    <b-row class="g-0">
                        <b-col lg="6">
                        <b-card-body class="h-100">
                            <div>
                            <h5 class="mb-1">맞춤형 개발</h5>
                            </div>

                            <div class="py-4">
                            <h2>
                                별도 견적문의
                            </h2>
                            </div>

                            <div class="text-center plan-btn mt-2">
                                <router-link to="/pages/contactus" class="btn btn-success w-sm waves-effect waves-light">개발문의</router-link>
                            </div>
                        </b-card-body>
                        </b-col>
                        <b-col lg="6">
                        <b-card-body class="border-start mt-4 mt-lg-0">
                            <b-card-header class="bg-light">
                            <h5 class="fs-15 mb-0">진행철차:</h5>
                            </b-card-header>
                            <b-card-body class="pb-0">
                            <ul class="list-unstyled vstack gap-3 mb-0">
                                <li>
                                    <span class="text-success fw-semibold">1.</span> 생산라인 분석
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">2.</span> 맞춤형 개발기획
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">3.</span> 업무 협의
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">4.</span> 개발견적 산출
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">5.</span> 개발진행
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">6.</span> 베타테스트
                                </li>
                                <li>
                                    <span class="text-success fw-semibold">7.</span> 적용완료
                                </li>
                            </ul>
                            </b-card-body>
                        </b-card-body>
                        </b-col>
                    </b-row>
                    </b-card>
                </b-col>

                </b-row>
            </b-container>
        </section>
        <Footer></Footer>
    </div>
</template>